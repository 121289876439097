/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, Subtitle, Text, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Hlavní stránka"}>
        <Column className="--menu pb--02 pt--02" menu={true} name={"dslsaa8pk24"} style={{"backgroundColor":"rgba(22, 25, 37, 1)"}}>
          
          <Menu className="--center --full pb--02 pt--02" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box pr--12" style={{"maxWidth":100}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/4652793655114d1ab705f1babba23c2c.svg"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="menu-logo-box mt--02" style={{"maxWidth":331}}>
              
              <Subtitle className="subtitle-box fs--24 w--900 subtitle-box--invert ls--02" content={"<a href=\"/\">M.A.T.</a>"}>
              </Subtitle>

              <Text className="text-box w--300 text-box--invert lh--14 mt--0" content={"<a href=\"/\" style=\"color: rgb(255, 255, 255);\">Okna &amp; dveře</a>"}>
              </Text>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"/reference"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"http://mat-okna.cz/#homepage-sluzby"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Služby</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"/poptavka"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Poptávka</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Kont</span><a class=\"btn admin__module\" href=\"#\" style=\"font-family: var(--font1); font-size: 1.125rem; letter-spacing: 0.01em; display: inline !important;\"><div style=\"display: inline !important;\"><span style=\"color: rgb(255, 255, 255);\">akt</span></div></a>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn1 btn-box--pbtn3 fs--18 w--600 pl--02 pr--02" innerClassName="pb--10 pt--08" target={""} content={"<span style=\"color: rgb(255, 255, 255);\">+420 734148215</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-d7de7e bg--center mb--0 mt--0 pb--80 pl--40 pt--80" name={"8ujloyxxd1q"} style={{"backgroundColor":"rgba(0,0,0,1)"}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17432/bece59a9d8d24793ad243b464fba376d_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --hvr5 --shape5 js-anim  --anim4 --anim-s8 --shadow4 --left el--2 mb--80 mt--80 pl--40 pr--0" anim={"4"} animS={"8"} columns={"2"}>
            
            <ColumnWrapper className="--hvr3 --border3 --shadow2 pb--40 pl--40 pr--40 pt--40" anim={null} animS={null} style={{"maxWidth":300,"backgroundColor":"rgba(255,255,255,1)"}} border={"3"}>
              
              <Title className="title-box w--500" style={{"maxWidth":156}} content={"Klasické, na míru, i atypické."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":438}} content={"Téměř 20 let provádíme montáž oken a dveří v celém spektru rozsahu realizací – od soukromého bydlení až po autosalony a hotely."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --shadow4 pb--25 pl--30 pr--30 pt--25" anim={"4"} animS={"5"} style={{"maxWidth":264,"backgroundColor":"rgba(228,60,60,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/60caca6892e8413a9f48caa4ad4a0390.svg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":21}} srcSet={""}>
              </Image>

              <Text className="text-box fs--18 w--500 text-box--invert mt--20 pl--0 pr--0" style={{"maxWidth":438}} content={"Specializujeme se na prodej, montáž a servis plastových, dřevěných a hliníkových oken a kvalitních designových dveří"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--cColor2 btn-box--left fs--20 pl--0 pr--0" href={"/reference"} content={"Reference"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"ezih9za32oo"}>
          
          <ColumnWrap className="column__flex el--3" anim={null} animS={null} style={{"maxWidth":1300}} columns={"3"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":410}}>
              
              <Image className="pl--02" src={"https://cdn.swbpg.com/o/11212/fabe716c916a4f5a8cfffb9cda8cc315.svg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":32}} srcSet={""}>
              </Image>

              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">Připravíme Vám </span>"}>
              </Text>

              <Title className="title-box fs--36 mt--06" content={"cenovou kalkulaci"}>
              </Title>

              <Text className="text-box mt--12" style={{"maxWidth":336}} content={"Nezávazně vám spočítáme na kolik vás okna budou vycházet.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":410}}>
              
              <Image className="pl--02" src={"https://cdn.swbpg.com/o/11212/fabe716c916a4f5a8cfffb9cda8cc315.svg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":32}} srcSet={""}>
              </Image>

              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">poradíme si</span>"}>
              </Text>

              <Title className="title-box fs--36 mt--06" content={"s konkurencí"}>
              </Title>

              <Text className="text-box mt--12" style={{"maxWidth":336}} content={"Pošlete nám &nbsp;vaší nabídku od konkurence - srovnáme naše možnosti."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":410}}>
              
              <Image className="pl--02" src={"https://cdn.swbpg.com/o/11212/fabe716c916a4f5a8cfffb9cda8cc315.svg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":32}} srcSet={""}>
              </Image>

              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">poradíme vám</span>"}>
              </Text>

              <Title className="title-box fs--36 mt--06" content={"s vašim domovem"}>
              </Title>

              <Text className="text-box mt--12" style={{"maxWidth":336}} content={"Nabídneme vám možnosti, které se hodí právě k vám domů.\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"1a08x7m91pdh"} style={{"backgroundColor":"rgba(22, 25, 37, 1)"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--36 title-box--invert pl--0" content={"Reference"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/db2cc03888e445078a114bde0692370c_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/db2cc03888e445078a114bde0692370c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/db2cc03888e445078a114bde0692370c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/db2cc03888e445078a114bde0692370c_s=860x_.jpg 860w"} position={{"x":"0%","y":"-84.72222222222214%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/221030e5fdc949fb9049f457e6133a02_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/221030e5fdc949fb9049f457e6133a02_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/221030e5fdc949fb9049f457e6133a02_s=660x_.jpg 660w"} position={{"x":"0%","y":"-84.72222222222214%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/3b7e335ccb454badb623fd6fc86d113e_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/3b7e335ccb454badb623fd6fc86d113e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/3b7e335ccb454badb623fd6fc86d113e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/3b7e335ccb454badb623fd6fc86d113e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/3b7e335ccb454badb623fd6fc86d113e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/3b7e335ccb454badb623fd6fc86d113e_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"-100%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --left" anim={"2"} animS={"5"}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--pbtn3 btn-box--center pl--0 pr--0" href={"/reference"} content={"Všechny reference"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"homepage-sluzby"}>
          
          <ColumnWrap className="column__flex el--1" anim={null} animS={null} style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="--center pl--20 pr--20" animS={"8"} style={{"maxWidth":""}}>
              
              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">co ďeláme</span><br>"}>
              </Text>

              <Title className="title-box fs--36 mt--06" content={"Naše služby"}>
              </Title>

              <Text className="text-box mt--12" style={{"maxWidth":444}} content={"Se vším Vám umíme poradit – jestli jsi nejste jistí, kontaktujte nás.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--3 pb--10 pt--10" anim={"2"} animS={"5"} style={{"maxWidth":1300}} columns={"3"}>
            
            <ColumnWrapper className="--hvr2 --shadow5 --center pb--30 pl--20 pr--20 pt--25" animS={"8"} style={{"maxWidth":410,"backgroundColor":"rgba(228,60,60,1)"}}>
              
              <Title className="title-box fs--36 title-box--invert mt--06" content={"Oprava a<br>údržba oken"}>
              </Title>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Servisujeme závady oken –&nbsp;&nbsp;problémy se zavíráním, hlučnost a další."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shadow5 --center pb--30 pl--20 pr--20 pt--25" animS={"8"} style={{"maxWidth":410,"backgroundColor":"rgba(228,60,60,1)"}}>
              
              <Title className="title-box fs--36 title-box--invert mt--06" style={{"maxWidth":236}} content={"Prodej a montáž&nbsp; oken"}>
              </Title>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Plastové, dřevěné a hliníkové okna včetně HS portálů a vchodových dveří."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shadow5 --center pb--30 pl--20 pr--20 pt--25" animS={"8"} style={{"maxWidth":410,"backgroundColor":"rgba(228,60,60,1)"}}>
              
              <Title className="title-box fs--36 title-box--invert mt--06" content={"Interiérové<br>dveře"}>
              </Title>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Nabízíme kvalitní a designové interiérové dveře."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-bztsds --parallax pb--60 pt--60" name={"90k7zd4mxkd"} style={{"backgroundColor":"rgba(22,25,37,1)"}} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--600 swpf--uppercase lh--12" style={{"color":"var(--color-variable-1)"}} content={"ozveme se vám"}>
              </Text>

              <Title className="title-box title-box--invert mt--10" content={"Zanechte nám kontakt"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"w4gpm3m7d1d"} style={{"backgroundColor":"rgba(22,25,37,1)"}}>
          
          <ColumnWrap className="column__flex --left el--4" anim={null} animS={null} style={{"maxWidth":""}} columns={"4"}>
            
            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/4652793655114d1ab705f1babba23c2c.svg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":120}} srcSet={""}>
              </Image>

              <Title className="title-box fs--24 w--900 title-box--invert ls--02 lh--14 mt--10 pl--06" content={"M.A.T."}>
              </Title>

              <Text className="text-box w--300 text-box--invert lh--14 mt--0 pl--08" style={{"maxWidth":336}} content={"Okna &amp; dveře\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">M.A.T. Okna a dveře</span>"}>
              </Text>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Všestary 173<br>593 12 Hradec Králové&nbsp;<br>IČO 07195702<br>DIČ CZ07195702<br>mat-okna@email.cz&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">Cenové nabídky</span>"}>
              </Text>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Tereza Fejtková<br>+420 734 148 215<br>mat-okna@email.cz \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">Technické dotazy</span>"}>
              </Text>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Technik<br>+420&nbsp;774 774 593<br>mat-okna@email.cz "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}